.sidebar {

  // @include make-sm-column(3);
  // @include make-md-column(3);
  // @include make-lg-column(3);
  @include make-one-fourth;
}
.content {

  // @include make-sm-column(12);
  // @include make-md-column(9);
  // @include make-lg-column(9);
  @include make-three-fourth;
}
.main {

  // padding-top: 24px;
}
.main__search {
  .sidebar {

    // padding: 0;
  }
  .content {

    // padding: 0;
  }
}
.row {
  @include clearfix();
}
.collapse {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-property: height,visibility;
  -o-transition-property: height,visibility;
  transition-property: height,visibility;
}
.collapse.in {
  display: block;
}

@media print {
  .sidebar, .content{
    display: none;
  }
} 