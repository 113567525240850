.add-more-products {
	width:50%;
	&__container{
		float: left;
		.dropdown-menu{
			min-width: 100%;
			cursor: pointer;
		}
		.dropdown-menu li:focus, .dropdown-menu li:hover {
		    color: $brand-color-5;
		    background-color: $brand-color-4;
		}
		.dropdown-menu li {
		    display: block;
		    padding: 3px 20px;
		    font-weight: 400;
		    line-height: 1.42857143;
		    color: $brand-color-5;
		}
		.open .dropdown-toggle.btn-primary {
		   color: $light;
		   background-color: $brand-color-10;
		}
	}
	button {
		padding: 6px 12px;
   		color: $light;
   		background-color: $brand-color-2;
   		
   		&:focus, &:hover{
   			background-color: $brand-color-10;
		}
	   &:disabled{
	   	    cursor: not-allowed;
	   }		
	}
		
	&__content{
		@include clearfix;
		box-shadow: $row-shadow-color;
	    padding-top:$base-padding/2;
	    padding-bottom:$base-padding/2;
    	background-color: $row-bgcolor;
	    padding-left: $base-padding/6;
    	padding-right: $base-padding/6;
	    width: 100%;
    
	}
	&__button{
		padding-top: $base-padding/6; 
	    padding-bottom: $base-padding/6;
	    background-color: $brand-color-2;
	    width: 13%;
	    color: $light;
	    font-weight: 700;
        margin-left: 0.63rem;
        float: left;
        text-align: center;
        margin-top: 0.27rem;
    	margin-bottom: 0.27rem;
	}
}