.filter-wrapper {
  &__header {
    padding-bottom: $base-padding/3;
    &_name {
      text-transform: uppercase;
      color: $brand-color-2;
    }
    &_filter-reset {
      text-transform: uppercase;
      background: transparent;
      border: none;
      cursor: pointer;
      float: right;
      padding: 0;
    }
  }
}

.star-filter {
  @include clearfix;
  &__checkbox {
    float: left;
    padding-top: 2px;
  }
  &__stars {
    float: left;
    margin-left: 0.625rem;
    @include clearfix;
    &_star {
      float: left;
      color: $star-rating;
    }
  }
  &__star-count {
    float: right;
  }
}
