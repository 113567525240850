@mixin make-alert_private($bgcolor, $color){

	background-color: $bgcolor;
    // color: lighten($color, 50);
    color:$color;
    padding: $base-padding/2;
    .close {
    background: none;
    border: none;
    color: $color;
    font-size: 30px;
    position: relative;
    top: -17px;
    float: right;
  }

}
@mixin make-alert($bgcolor, $color){
	@include make-alert_private($bgcolor, $color);
}