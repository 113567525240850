.matrix-table-wrapper {
  @include clearfix;
  max-width: 100%;
  border: 3px solid $brand-color-2;
  background-color: $light;
  //position: relative;
}
.matrix-table {
  border: 3px solid $table-border;
  border-collapse: collapse;
  overflow: auto;
  width: 100%;
  table-layout: fixed;
  td,
  th {
    padding: $base-padding/6;
    text-align: center;

    // width: 30px;
    border-bottom: 1px solid $table-row-border;
  }
  td > a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
}
.table__row,
.table__column {
  &_label {
    text-transform: uppercase;
    position: relative;
    background-color: $table-label-bgcolor;

    //width: 20px;
    //padding: 5px;
    color: $table-label;
    margin: 1px;
    // display: inline-block;
  }
  &_label:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
  }
  &_header {
    font-weight: 400;
    color: $table-header;
    text-align: center;
    font-family: $font-family-number;
  }
}

.table__row_label {
  border-right: 1px solid $light;
  padding-bottom: 1.1rem;
}
.table__row_label:after {
  border-right: 1px solid $light;
  top: 100%;
  left: 50%;
  border-left: 5px solid transparent;
  border-top: 5px solid $table-label-bgcolor;
  border-right: 5px solid transparent;
  margin-left: -5px;
}
.table__column_label:after {
  left: 100%;
  top: 50%;
  border-top: 5px solid transparent;
  border-left: 5px solid $table-label-bgcolor;
  border-bottom: 5px solid transparent;
  margin-top: -5px;
}
.stops__table {

  //width: 18%;
  float: left;
  td,
  th {
    text-align: center;
    padding: $base-padding/6;
    border-bottom: 1px solid $table-row-border;
  }
  .last {
  	 border-bottom: none;
  }
}

.table-row,
.table-column{

  &_label {
     padding-top: 0.5rem;
     height: 33px;
  }
  &_image {
     padding-bottom: 0.2rem;
     min-height: 33px;
  }
  
}

.data__table {

  //width: 80%;
  //float: left;
  overflow: auto;
  table {
    min-width: 100%;
    background-color: $light;
  }
  td,
  th {
    min-width: 60px;
    text-align: center;
    padding: $base-padding/6;
    border-bottom: 1px solid $table-row-border;
  }
  td >a {
    text-decoration: none;
    font-family: $font-family-number;
    color: inherit;
    cursor: pointer;
    &:hover {
    	color:$brand-color-1;
    	text-decoration: underline;
    }
  }
    .active {
    	color:$brand-color-2;
    }
}
.matrix-view {
  margin-top: -10px;
  margin-bottom: 15px;
  position: relative;
}
