.alert__message {

  margin-bottom: $base-padding/2;

  &--error {
  	 @include  make-alert(lighten($brand-color-1, 40), $brand-color-1);
  }
  &--review-error {
  	@include  make-alert(lighten($brand-color-1, 40), $brand-color-1);
  	margin-left: .9375rem;
    margin-right: .9375rem;
  }
  
  &--info {
     @include  make-alert($brand-color-3, $brand-color-2);
  }
  
  &--success {
  	 @include  make-alert($light-green, $green);
  }
  
  &--warning {
  	 @include  make-alert($brand-color-12, $brand-color-13);
  }
  &--text{
  	text-align: center;
    font-size: 1rem;
  }
}
.alert__message-container{
	  padding-left: $base-padding/2;
	  padding-right: $base-padding/2;
}