.retrieve-booking {
  @include create-main-wrapper;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  
    &_card {
    	box-shadow: $row-shadow-color;
	    &_header {
	      text-transform: none;
	      color: $brand-color-2;
	      border-bottom: 1px solid $brand-color-2;
	      margin-bottom: $base-margin/2;
	      padding-left:0px;
	    }
	    .header_text {
	     	margin-right: $base-margin;
			font-size: 1.25rem;
	    	font-weight: bold;
		
	    }
	    &_content{
		    max-width: 400px;
		    margin: 0 auto;	    
	    }	
	}
	
	&__form{
	    width: 60%;
	    margin-top: 5%;
	    margin-left: 20%;
	    padding-left: $base-padding/2;
	    padding-right: $base-padding/2;	
	}  
	
	.form-field-validation-error{
	    width: 100%;
    	color: $brand-color-1;
	}	
	
	&__wrapper{
		margin-bottom: $base-margin/2;
		&_label{
		    margin-bottom: 0;
    		color: $brand-color-2;
    		font-size: 0.875rem;
		}
		&_value{
		   font-size: 0.875rem;
		   width: 100%;
		   padding: 0.5rem 1rem;
		   background: none;
		   font-size: 0.875rem;
		   vertical-align: middle;
		   line-height: normal;
		   background-color: $input-bgcolor;
		   border: 1px solid;
		   border-color: $input-border-color;
		   max-height: 34px;		
		}
	}
    
	&__save-button{
	    width: 100%;
		@include clearfix;
	    padding-bottom: 2rem;		
		&_submit {
		  padding-top: 0.3125rem;
		  padding-bottom: 0.3125rem;
		  background-color: $brand-color-1;
		  background-image: none;
		  width: 100%;
		  color: $light;
		  font-weight: 700;
		  &:hover {
		    background-color: darken($brand-color-1, 20);
		  }
		}
	}    

}
