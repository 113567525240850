.dynamic-pax-info-form {
  margin-bottom: 0.5rem;
  &__content {
    @include clearfix;
  }
  &__link {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    cursor: pointer;
    text-align: left;
    color: $brand-color-2;
  }
  .dynamic-form {
    @include clearfix;
    width: 100%;
    .dynamic-grouped-form {
      border: none;
      padding: 0px;
      min-height: 5rem;
    }
    .dynamic-selection-grouped {
      width: 100%;
      display: table;
      content: "";
      padding: 0rem;
      .dynamic-single-select-content {
        width: 24%;
      }
    }
    .dynamic-single-select,
    .dynamic-multi-select,
    .dynamic-date-field,
    .date-field,
    .dynamic-date-range-field,
    .dynamic-selection-grouped {
      select {
        border: 1px solid;
        border-color: $input-border-color;
      }
    }
    .dynamic-single-select {
      min-height: 3.5625rem;
    }
    .four_col_layout {
      padding-right: 0.5rem;
    }
    .dynamic-number-field,
    .dynamic-text-field,
    .dynamic-text-area-field,
    .dynamic-single-select,
    .dynamic-multi-select,
    .dynamic-date-field,
    .date-field,
    .dynamic-date-range-field,
    .dynamic-time-field,
    .dynamic-html-field,
    .dynamic-selection-grouped {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 4rem;
      &__value {
        padding-right: 0;
      }
    }
    .dynamic-phone-number {
      &__label {
        margin-bottom: 0;
        color: $brand-color-2;
        font-size: 0.875rem;
      }
      &__country-code {
        width: 30%;
        float: left;
        padding-right: 0.5rem;
        input[type="text"] {
          padding: 0.5rem;
        }
      }
      &__mobile-number {
        width: 70%;
        float: left;
        background: none;
        font-size: 0.875rem;
        vertical-align: middle;
        line-height: normal;
        max-height: 2.125rem;
      }
    }

    .form-field-validation-error {
      padding-top: 0.3125rem;
    }
    .itemcontainer{
    max-height: 16.25rem;
    overflow-y: scroll;
    z-index: 5;
    position: absolute;
    background-color: $light;
    border-top: 1px solid $brand-color-2;
    border-right: 1px solid $brand-color-22;
    border-bottom: 1px solid $brand-color-22;
    border-left: 1px solid $brand-color-22;
    width: 100%;
    &__item{
      &_title {
        @include clearfix;
        margin-bottom: 0;
        border-bottom: 1px solid $brand-color-22;
        padding-top: 1rem;
        padding-bottom: 0.9375rem;
        padding-left: 1rem;
        padding-right: 1rem;
        > .autosuggest-country {
          float: right;
        }
      }
      &:last-child p:last-child {
        border-bottom: none;
      }
    }
    }
    .four_col_layout{
      min-height: 5rem;
    }
  }

  &__header {
    @include clearfix;
    width: 100%;
    background-color: $brand-color-4;
    &_title {
      line-height: $modal-title-line-height;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      display: block;
      text-align: center;
      color: $brand-color-2;
      font-size: 1.25rem;
      margin: 0;
      width: 95%;
      float: left;
    }
    &_close-icon {
      cursor: pointer;
      float: right;
    }
  }

  &__heading-container {
    @include clearfix;
    display: inline-table;
    width: 100%;
    border-bottom: 1px solid $brand-color-3;

    &_heading,
    &_close-button {
      font-size: 1.25rem;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }
    dynamic-default-form__text-field 
    &_heading {
      width: 90%;
      color: $brand-color-2;
      text-align: center;
      float: left;
    }

    &_close-button {
      text-align: right;
      cursor: pointer;
      float: right;
    }
  }
  &__summary {
    padding: 0.725rem;
    border: 10px solid $brand-color-3;
    margin-bottom: 0.725rem;
    font-family: $font-family-number;
  }

  &__content-disable {
    cursor: not-allowed;
  }
  .dynamic-default-form__auto-suggestion {
    position: relative;
  }

  &__checkbox{
    color: $brand-color-2;
    font-weight: 700;
    float: right;
    text-align: right;
    .checkbox-component{
      font-size: .875rem;
    }
  }
}
