@mixin create-product-cart-display_private {
  &-cart {
    padding: $base-padding/5;

    &__product-header {
      @include clearfix;
      background-color: $body-bg;
      padding-bottom: .175rem;
      margin-left: -12px;
      margin-right: -12px;
      padding-left: 0.725rem;
      padding-right: 0.725rem;
      margin-top: -6px;
      padding-top: .375rem;
	  border-top: 1px solid  $brand-color-2;
	  border-bottom: 1px solid  $brand-color-2;
      &_index {
        float:left;
        width: 50%;
        text-align: left;
        color: $brand-color-2;
      }

      &_product-type {
        float:left;
        width: 50%;
        text-align: right;
        text-transform: uppercase;
        color: $brand-color-2;
      }

	  &_pnr {
  		color: $brand-color-2;
		float: left;
		width: 50%;	
		text-align: left;
	  }

      &_product-price {
        @include make-one-fifth;
      }

      &_product-remove-button {
        @include make-one-fifth;
          >i {
              &:hover {
                cursor: pointer;
              }
          }
      }
    }

  ul {
    
    li{
    width: 100%;
    margin-bottom: 4px;
    }
  }
    &__product-details-header {
      @include clearfix;
      padding-top: .375rem;
      padding-bottom: .125rem;
      border-bottom: 0.5px solid;

      &_product-name {
        float: left;
        width: 50%;
        @include text-overflow;
        text-align: left;
        color: $gray-base;
        font-weight: 400;
        word-break: break-word;
        white-space: normal;
      }

      &_product-extra-details {
        float: left;
        width: 50%;
        @include text-overflow;
        text-align: right;
        text-transform: uppercase;
        color: $gray-base;
        font-weight: 400;
      }
	  &_plan-details-link {
	  	font-weight: 400;
	  	width: 100%;
    	float: left;
		a {
			color: $brand-color-2;
		}
	  }
    }


    &__see-more {
      @include clearfix;
      padding-top: $base-padding/5;
  		&_button {
          float: left;
  	      background-color: $brand-color-2;
  	      color: $light;
  	      text-transform: uppercase;
  	      font-size: $font-size-h6;
  	      font-weight: 600;
          margin-right: 3.125rem;
		  outline:none;
		
  	    .select-button__triangle {
  	        position: relative;
  	        margin-left: 5px;
  	        top: 10px;
  	        height: 0;
  	        width: 0;
  	        border: 7px solid transparent;
  	        border-top: 7px solid $light;
  	      }
        }

	      &_button-active {
          float: left;
		      background-color: $brand-color-3;
		      color: $brand-color-2;
		      text-transform: uppercase;
		      font-size: $font-size-h6;
		      font-weight: 600;
          	  margin-right: 3.125rem;
          	  outline:none;

	      	 .select-button__triangle-active {
			        position: relative;
			        margin-left: 5px;
			        top: 10px;
			        height: 0;
			        width: 0;
			        border: 7px solid transparent;
			        border-top: 7px solid $brand-color-2;
			        color: 7px solid $brand-color-2;
			      }
	      }

        &_remove-button {
          float: right;
          background-color: $brand-color-1;
          border: none;
          line-height: inherit;
          -webkit-border-radius: 0;
          border-radius: 0;
          background-image: none;
          color: $light;
          font-weight: 600;
          font-size: 0.675rem;
          text-transform: uppercase;
          padding-left: 0.375rem;
          padding-right: 0.375rem;
        }

     }

     &__content {
       padding-left: 0.125rem;
       padding-top: 0.375rem;

       &_heading {
         color: $brand-color-2;
         text-align: left;
         padding-bottom: 0.375rem;
         padding-top: 0.375rem;
         border-bottom: 0.5px solid $gray-lighting;
       }

       &_row {
         @include clearfix;
         border-bottom: 0.5px solid $gray-lighting;
         padding-top: 0.375rem;
         padding-bottom: 0.375rem;
         .air-cart-row, .dp-cart-row, .hotel-cart-row, .package-cart-row, .sightseeing-cart-row, .cars-cart-row, .transfer-cart-row, .miscellaneous-cart-row, .insurance-cart-row, .visa-cart-row, .marhaba-cart-row {
           &__title {
             float: left;
             width: 50%;
             text-align: left;
           }
           &__value {
             float: left;
             width: 50%;
             text-align: right;
             text-transform: capitalize;
           }
           &__essentialInfo{
            float: left;
             width: 100%;
             text-align: left;
             max-height: 100px;
             overflow-y: scroll;
           
           }
           &__essentialHeader{
             float: left;
             width: 100%;
             text-align: left;
                    
           }
         }
         
         .tax-detail {
			@include create-cart-tax-display;
         }
       }
       
        &_cancellation-link {
          text-transform: uppercase;
          color: $brand-color-2;
          float: right;
          font-size: $font-size-h6;
        }

        &_additional-notes {
          border-bottom: 0.5px solid $gray-lighting;
          font-size: $font-size-base;
          cursor: pointer;
          a {
            color: $brand-color-2;
          }
          & :hover{
            text-decoration: underline;
          }
        }

       &_price-row {
         @include clearfix;
         border-bottom: 0.5px solid $gray-lighting;
         padding-top: 0.5rem;
         padding-bottom: 0.5rem;
         color: $brand-color-2;
         .air-cart-price-row, .dp-cart-price-row, .hotel-cart-price-row, .package-cart-price-row, .sightseeing-cart-price-row, .transfer-cart-price-row, .cars-cart-price-row, .miscellaneous-cart-price-row, .insurance-cart-price-row, .visa-cart-price-row,
         .marhaba-cart-price-row {
           &__title {
             float: left;
             width: 50%;
             text-align: left;
           }
           &__value {
             float: left;
             width: 50%;
             text-align: right;
             font-family: $font-family-number;
           }
           &__button{
	           float: left;
	           width: $base-padding*4;
	           text-align: center;
	           background-color: $brand-color-2;
	           color:$light;
           }
         }
       }
        &_air-missed-saving-detail {
         	width:100%; 
			font-size: $font-size-h5; 
			border-collapse: collapse;
			.air-missed-saving-detail{
				&__header{
					padding: $base-padding/3;
					background-color: $gray-lighter; 
					width: 25%;
				}
				&__value{
					align:center;
					padding: $base-padding/3;
					background-color: $light-red;
					.currency{
						padding: $base-padding/10;
					}
		 		}
			}
         }
     }

	&__bolder-font {
  		font-weight: 900;
		float: left;
    	width: 50%;
		text-align: right;
  	}
  
    &__white-background {
  		background-color: $brand-color-23;
	}

  }


}

@mixin  create-product-cart-display { 
	@include create-product-cart-display_private; 
}