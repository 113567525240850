.modal__content {
	overflow-y: auto;
	margin-top: 0;
	margin-bottom: 5px !important;
	padding-top: $base-padding/2;
	
	&_terms-and-conditions-loader{
		@include make-loader;
	}
	
	&_title{
		text-transform: uppercase;
	 	color: $brand-color-2;
		border-bottom: 1px solid $brand-color-4;
		margin-bottom: 5px;
	}
}

.term-and-condition-btn {
	cursor: pointer;
}
