.cancellation-policy {
	&_product {
		&-header {
			border-bottom: 1px solid $gray-lighting;
			color: $brand-color-2;
			padding: $base-padding/3;
		}
		&-content {
            padding: $base-padding/3;
		}
	}
}