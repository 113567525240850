.login__page {
  @include create-main-wrapper;
  // @include create-row;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;

  // @include create-row-card-footer;
  &_card {
    box-shadow: $row-shadow-color;
    &_header {
      text-transform: none;
      color: $brand-color-2;
      border-bottom: 1px solid $brand-color-2;
      margin-bottom: $base-margin/2;
      padding-left:0px;
    }
    .header_text {
      margin-right: $base-margin;
		font-size: 1.25rem;
    	font-weight: bold;
	
    }
    &_footer {
      border-top: 1px solid $brand-color-2;
      padding-top: $base-padding/3;
      padding-bottom: $base-padding/3;
	      
	.term-and-conditions, .privacy-policy {
		color: $brand-color-1;
		text-transform: lowercase;
		padding: 0 0.2rem;
		display: contents;
		&:hover {
			color: $brand-color-1;
		}
	}

    }
    .footer_text {
      margin-bottom: 0;
    }
  }
  &_form {
    max-width: 400px;
    margin: 0 auto;
  }
  &_input_wrapper {
    margin-bottom: $base-margin/2;
   
  }
  // label,
  // input[type="text"] {
  //   display: block;
  //   width: 100%;
  // }
  label {
    // width: 100%;
    margin-bottom: 0;
    color: $sf-label-color;
    font-size: 0.875rem;
  }
  select{
	width: 90%;
	padding: 0.5rem 1rem;
	padding-left: 0;
    background: 0 0;
  }  
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 0.5rem 1rem;
    background: none;
    font-size: 0.875rem;
    vertical-align: middle;
    line-height: normal;
    background-color: $input-bgcolor;
    border: 1px solid;
    border-color: $input-border-color;
    max-height: 34px;
  }
  &_button {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    background-color: $brand-color-1;
    border: none;
    line-height: inherit;
    border-radius: 0;
    background-image: none;
    width: 100%;
    color: $light;
    font-weight: 700;
    &:hover {
      background-color: darken($brand-color-1, 20);
    }
  }
  

    &_btn-socialFB {
     @include social-site-button($brand-color-8);
    }
    &_btn-socialGoogle {
     @include social-site-button($brand-color-9);
    }
	&_links{
    	@include clearfix;
    	a {
	    	text-transform: none;
	        color:$brand-color-1;
	        text-decoration: none;
	        margin-right: 7px;
	        &:hover,
	        &:focus {
	          text-decoration: underline;
	          color:darken($brand-color-1, 10);
	        }
	    }
      	
    }
      
}
.pax {
  &__title {
    width: 30%;
    display: inline-block;
  }
  &__fname {
    width: 70%;
    display: inline-block;
    float: right;
  }
  &__gender {
    float: left;
    width: 33%;
    &_wrapper {
      @include clearfix;
    }
  }
}
.login-popover{
	height: auto;
	.modal-content{
	    height: 650px;
	    width: 800px;
        left: 10%;
	}
	.content{
		width: 100%
	}
	&__close{
	float: right;
    background: none;
    border: none;
    float: right;
    font-size: 1rem
	}
}
.logout-modal, .delete-account-modal{
    max-width: 40%;
    margin-left: 30%;
    margin-top: 10%;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    	&__text{
		    color: $brand-color-2;
		    padding-bottom: $base-padding/3;
    		margin-left: -20%;
    		margin-right: -20%;
	    }
	    &__button{
    	    width: 30%;
			padding: $base-padding/5;
			background-color: $brand-color-3;
	    }
}
.delete-account-modal__text {
	font-size: 1.125rem;
    font-family: inherit;
    font-weight: 500;	
    padding-bottom: 0.9rem
}

.modal-open{
	.logout-modal.modal, .delete-account-modal.modal{
		overflow-x: hidden;
    	overflow-y: hidden;
	}
}
.reset-password{
	.login__page{
	    padding-left: 25rem;
   	    padding-right: 25rem;
   	    padding-top: 5rem;
   	    padding-bottom: 1rem;
	}
	.login__page_card_content {
   	    margin-bottom: 4rem;
  	    margin-top: 3rem;
    }
    .validation-error {
    	color: $brand-color-1;
    }
}

.my-account, .auth {
	&__login-form {
		.content {
			width: 60%;
		    margin-top: 5%;
		    margin-left: 20%;
		    float: none;
		}
	}
}
.popover{
	.login__page_card{
		box-shadow: 0 0;
		padding:0px;
	}
	.login__page_forgot-password-message{
		max-width:15rem;
	}
}
.login__page_forgot-password-message{
		font-size:0.9rem;
}
.login__page_button-separator{
	margin-top:5px;
	h3.login__page_button-separator-label {
	
		position: relative;
    	z-index: 1;
    	text-align: center;
    
    	&:before {
        	border-top: 1px solid $brand-color-2;
        	content:"";
        	margin: 0 auto; 
        	position: absolute; 
        	top: 50%; left: 0; right: 0; bottom: 0;
        	width: 100%;
        	z-index: -1;
    	}
    	span { 
        	background: $light; 
        	padding: 0 15px; 
   		}
	}

}
