@mixin compare-description-separator {
    border-bottom: 2px solid $brand-color-4;
}

@mixin compare-separator {
    border-top: 2px solid $brand-color-2;
}

.product-compare{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap: 0.25rem;
    &__container {
        border: 2px solid $gray-lighter;
        padding: 0.5rem;
        border-radius: 10px;
        float: left;
        &_title {
            background-color: $brand-color-4;
            padding: 0 $base-padding/4;
            font-size: 0.985rem;
            text-transform: uppercase;
        }
        
        &_product-info {
            display: grid;
            grid-template-columns : 70% 30%;
            @include compare-description-separator;
            width: 100%;
            padding: $base-padding/4 0;
            height: 4.5rem;
        }
        
        &_product-content{
            @include compare-separator;
            width: 100%;
            
        }
    }
    &__container-one-half{
        margin: 0px;
    }

    &__container:last-child {
        margin: 0px;
    }
    
    .product-content{
        @include clearfix;
        width: 100%;
        &__header{
            color: $dark;
            padding-top: $base-padding/4;
            font-size: $font-size-h4;
        }
        
        &__trip{
            @include clearfix;
            @include compare-description-separator;
            &_iata-info, &_leg-info{
                @include make-one-third;
                padding: 0rem;
                min-height: 100px;
                text-align: left;
                padding-top: $base-padding/2;
            }
            &_iata-info{
                font-family: $font-family-number;
                text-align: left;
                font-size: 0.8rem;
                width: 20%;
            }
            &_iata-info:last-child{
                text-align: right;
            }

            .iata-info{
                &__time{
                    font-size: $font-size-h3;
                    color: $brand-color-2;
                }
            }
            
            .iata-leg-info{
                &__time{
                    font-size: $font-size-h3;
                    color: $brand-color-2;
                }
            }

            &_leg-info {
                position: relative;
                text-align: center;
                width: 60%;
                &:before,
                &:after {
                  width: 5%;
                  height: 1px;
                  background-color: lighten($brand-color-5, 30);
                  position: absolute;
                  top: 50%;
                }
                &:before {
                  margin-right: -10px;
                  right: 100%;
                }
                &:after {
                  margin-left: -10px;
                  left: 100%;
                }
                .leg-info {
                    &__stops{
                           padding-top: 1.5rem;
                    }
                }
              }
        }
        &__description{
            @include clearfix;
            @include compare-description-separator;
            width: 100%;
            padding: 0.25rem 0;

            &_label{
                width: 30%;
                float: left;
            }
            &_value{
                font-family: $font-family-number;
                width: 70%;
                float: left;
                text-align: right;
                color: $brand-color-2;

            }
            &_value-left-aligned{
                font-family: $font-family-number;
                width: 70%;
                float: left;
                text-align: left;
                color: $brand-color-2;
                word-wrap: break-word;
            }
        }
    }

    .product-info{
        @include clearfix;
        &__image-container {
            display: table;

            &_image {
                max-width: 290px;
            }
            &_image-name{
                margin-top: 0.25rem;
                text-transform: uppercase;
            }

        }
        &__price {
            text-align: right;
            padding-top: $base-padding/4;
            &_amount{
              font-size: $font-size-h3;
              font-family: $font-family-number;
              color: $brand-color-1;
            }
         
        }
    }

}

@media print{
    .product-content__description .product-content__description_value-left-aligned {
        width: 100%;
    }
    .product-content__description .product-content__trip .product-compare__container_product-info{
        break-inside:avoid;
    }
    .full-screen-modal__header{
        break-after:avoid;        
    }
}