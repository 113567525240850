.modal__content {
  overflow-y:scroll;
  max-height:550px;
  margin-top: 0px;
  margin-bottom: 30px;
  padding-top: 5px;
  padding-bottom: $base-padding/2;
  &_fare-rule-loader{
  @include make-loader;
  }

  .meal{
    max-height:500px;
    margin-top:-15px;
     &__container{
    &_header{
       text-transform: uppercase;
       color: $brand-color-2;
       height: 30px;
       padding-top:10px;
       padding-left:15px;
       background-color: $brand-color-3;
       margin-bottom:10px;
       >ul{
         >li{
            @include make-one-fourth;
         }
       }
     }
    &_row{
    @include make-row;
    width:100%;
    padding-bottom:10px;
    margin-top:5px;
    .meal{
      &__name,&__rate,&__quantity,&__price{
        @include make-one-fourth;
      }
      &__rate,&__quantity,&__price{
        padding-left:40px;
  }
  &__rate,&__price{
    font-family: $font-family-number;
  }
        &__name{
          cursor:pointer;
          >input{
            margin-right:15px;
            cursor:pointer;
          }
        }
        &__quantity{
          cursor:pointer;
          >select{
            width:50px;
            cursor:pointer;
          }
        }
        }
    }
    }
  }

 }
 .modal__footer{
   min-height:35px;
   .save-ancillary-button{
       padding-top: 0.3125rem;
       padding-bottom: 0.3125rem;
       margin-left:365px;
       margin-top:10px;
       background-color: $brand-color-1;
       border: none;
       line-height: inherit;
       border-radius: 0;
       background-image: none;
       width: 25%;
       color: $light;
       font-weight: 700;
       float: right;
       &:hover {
           background-color: darken($brand-color-1, 20);
       }
   }
 }
