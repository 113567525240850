.book-card {
  @include travel-card;
  &__table {
    font-family: $font-family-number;
    width: 100%;
    &-row {

    }
    tr {

    }
    td,
    th {
      border-bottom: 1px solid lighten($brand-color-5, 20);
      padding-top: 0.625rem;
      padding-bottom: 0.3125rem;

      //padding-left: 0.625rem;
      //padding-right: 0.625rem;
      font-weight: 600;
      &:first-child {

      }
      &:last-child {
        text-align: right;
      }
    }
  }
  a {
    color: $brand-color-2;
    text-transform: uppercase;
  }
  &__price {
    margin-bottom: $base-margin/2;
    &_currency {
      font-size: $font-size-h5;
    }
    &_amount {
      font-family: $font-family-number;
      font-size: $font-size-h2;
      color: lighten($brand-color-1, 20);
    }
  }
  &__button {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    background-color: $brand-color-1;
    border: none;
    line-height: inherit;
    border-radius: 0;
    background-image: none;
    width: 100%;
    color: $light;
    &:hover {
      background-color: darken($brand-color-1, 20);
    }
    &:disabled {
      background-color: lighten($brand-color-1, 20);
      &:hover {
        background-color: lighten($brand-color-1, 20);
      }
      cursor: not-allowed;
    }
    font-weight: 700;
  }
  .terms {
    margin-top: $base-margin/2;
    font-size: $font-size-h6;
    &__checkbox{
   		float: left;
    	margin-right: 5px;
    }
  }
  .terms-and-condition-text{
  	text-transform: uppercase;
  }
}
