.ancillary-modal {
    &__content {
      overflow-y: auto;
      margin-top: 20px;
      margin-bottom: 1.875rem;
      padding-top: $base-padding/2;
      padding-bottom: $base-padding/2;

      &_loader{
      padding: 0.938rem;
      margin-left: 45%;
      }
    }

    &__header {
      @include clearfix;
      font-size: $font-size-h4;
      width: 100%;
      &_close {
        width: 5%;
        float: right;
        text-align: right;
        cursor: pointer;
        color: $ancillary-modal-close-button-color;
      }
      &_info {
        margin-top: 0.438rem;
        font-size: $font-size-h3;
      	color: $ancillary-modal-header-font-color;
        background-color: $ancillary-modal-header-bg-color;
        height: 35px;
        padding: 0.313rem 0.313rem 0.313rem 0.438rem;
      }
    }
  }
  .top-heading__main {
    @include clearfix;
  }
  .main-header__info {   
    &_name {
      text-transform: capitalize;
      color: $ancillary-modal-header-font-color;
      margin-left: 0.313rem;
      font-family: $font-family-number;
      float: left;
    }
    &_price {
      color: $ancillary-modal-header-font-color;
      float: right;
      font-size: 0.875rem;
      margin: 0.125rem;
    }
  }
  

.ancillary-modal__content {
    overflow-y:scroll;
    max-height:550px;
    margin-top: 0px;
    margin-bottom: 1.875rem;
    padding-top: 0.313rem;
    padding-bottom: $base-padding/2;
    &_fare-rule-loader{
    @include make-loader;
    }
  
    .meal{
      max-height:500px;
      margin-top:-15px;

      .filter{
        width: 100%;
        &__input{
          width: 100%;
        }
      }
      &__container {
        &_header {
          text-transform: uppercase;
          color: $ancillary-modal-secondary-header-font-color;
          height: 1.875rem;
          padding-top: 0.625rem;
          padding-left: 0.938rem;
          background-color: $ancillary-modal-secondary-header-bg-color;
          margin-bottom: 0.625rem;

          .header-name {
            @include make-one-fourth;
            padding-left: 0px;
            padding-right: 0px;
          }

          .header-info {
            @include make-one-sixth;
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      &_row{
      @include make-row;
      width:100%;
      padding-bottom: 0.625rem;
      margin-top: 0.313rem;
      padding-top: 0.625rem;
      padding-left: 0.938rem;
      .meal{
        &__name,
        &__rate,
        &__quantity,
        &__price,
        &__text,
        &__text-format {
          @include make-one-sixth;
          padding-left: 0px;
          padding-right: 0px;
        }
        &__name,
        &__text {
          @include make-one-fourth;
        }
        &__rate,&__price{
          font-family: $font-family-number;
        }
        &__quantity {
          cursor: pointer;

          &_add-btn, &_remove-btn {
            color: $ancillary-modal-button-font-color;
            background: $ancillary-modal-add-button-bg-color;
            width: 60%;
            text-transform: uppercase;
          }
		   &_add-btn {
			 &:hover {
				background: darken($ancillary-modal-add-button-bg-color, 20);
			}
		   }
          &_remove-btn {
            background: $ancillary-modal-remove-button-bg-color;
			&:hover {
				background: darken($ancillary-modal-remove-button-bg-color, 20);
			}
          }
        }
        }
      }
      }
    }
  
  }
.ancillary-modal__footer {
  min-height: 35px;

  .save-ancillary-button {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-top: 10px;
    background-color: $ancillary-modal-save-ancillary-button-bg-color;
    border: none;
    line-height: inherit;
    border-radius: 0;
    background-image: none;
    width: 25%;
    color: $ancillary-modal-save-ancillary-button-font-color;
    font-weight: 700;
    float: right;

    &:hover {
      background-color: darken($ancillary-modal-save-ancillary-button-bg-color, 20);
    }
  }
}
  