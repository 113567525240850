.sorter {

  // @include make-row;
  @include clearfix;
  background-color: $sorter-bgcolor;

  // padding-top: $base-padding/5;
  // padding-bottom: $base-padding/5;
  color: $sorter-textcolor;
  margin-bottom: $base-margin/3;
  &__wrapper {
    @include clearfix;
    float: left;
    padding-top: $base-padding/6;

    // padding-bottom: $base-padding/6;
  }
  &__heading {
    padding-left: $base-padding/3;
    padding-right: $base-padding/3;
    padding: 3px;
    float: left;
  }
  &__list {
    float: left;
    ul {
      @include clearfix;
    }
    ul > li {
      padding-top: 2px;
      padding-bottom: 2px;
      background-color: $sorter-list-bgcolor;
      padding-left: $base-padding/3;
      padding-right: $base-padding/5;
      margin-left: $base-padding/10;
      float: left;
    }
  }
  &__tab-button{
    background-color: $brand-color-3;
    color: $brand-color-2;
    padding: 0.375rem 0.625rem;  
	float: left;
	margin-right: 3px;  
    cursor: pointer;
    font-size:12px;
  font-weight:600;  
    &_disabled{
      background-color: $gray-lighting;
      cursor: not-allowed;
    }
  }
  
  &__tab-list {
    float: right;
    position: relative;
  //  z-index: 1;
    ul {

      //  background-color: $brand-color-3;
      @include clearfix;
    }
    ul > li {
      background-color: $brand-color-3;

      // padding-left: 2px;
      // padding-right: 2px;
      margin-left: 2px;
      float: left;
      @include clearfix;
      > a {
        float: left;
        color: $brand-color-2;
        text-decoration: none;
		cursor:pointer;
        // padding-top: $base-padding/6;
        // padding-bottom: $base-padding/6;
        padding: $base-padding/5 $base-padding/3;
        &:hover {
    background-color: $brand-color-2;
    color: $light;
        }
      }
      // &:hover {
      //   &:after {
      //     background-color: $brand-color-3;
      //     position: absolute;
      //     margin-left: 3px;
      //     // margin-right: 3px;
      //     width: 95%;
      //     height: 1px;
      //     top: 100%;
      //   }
      // }
    }
  }
  .active {
    background-color: $brand-color-2;
    color: $light;
    position: relative;
    &:after {
      //margin-left: 3px;
     // top: 100%;
      // margin-right: 3px;
     // background-color: $brand-color-2;

      width: 0;
      height: 0;
  	  position:absolute;
 	  bottom: 0;
 	  left: 50%;
    z-index: 1;
    margin-left: -5px;
        border-left: 5px solid transparent;
 		 border-bottom: 5px solid $light;
 		 border-right: 5px solid transparent;
     margin-bottom: -3px;

    }
    a {
      color: $light;
    }
  }
}
